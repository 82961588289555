// We're using tinytime for the date functions:
// See: https://github.com/aweary/tinytime
import tinytime from "@ui/tinytime";

export function toDate(input: Date | string | number) {
  return new Date(input);
}

interface TinyTimeOptions {
  padHours?: boolean;
  padDays?: boolean;
  padMonth?: boolean;
}

// Nice date = Dec 31st, 2020
export const niceDate = createDateTemplate("{MM} {Do}, {YYYY}");
export const niceDateWithTime = createDateTemplate("{MM} {Do}, {YYYY} at {h}:{mm}{a}");

// A helper for creating a rendering function
export function createDateTemplate(input: string, o?: TinyTimeOptions): (d: Date | string | number) => string {
  const template = tinytime(input, o);

  return (d: Date | string | number) => template.render(toDate(d));
}

// date -> 8:43
export const time24h = createDateTemplate("{H}:{mm}", { padHours: true });
export const inputDate = createDateTemplate("{YYYY}-{Mo}-{DD}", { padDays: true, padMonth: true });
